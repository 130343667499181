import React from "react";
import Seo from "../../components/seo";
import Industry from "../../layouts/Industries/Industry";
import {graphql, useStaticQuery} from 'gatsby';
import Layout from "../../components/layout";
import {Factories} from '../../components/svgs/industries';

const FactoriesIndustryPageFr = () => {
  const data = useStaticQuery(graphql`
    query FactoriesIndustryDatasFr {
      allContentfulSecteurs(filter: {contentful_id: {eq: "626he1WN2uKETazng2SuCd"}}) {
        edges {
          node {
            name
            permalink
            contentful_id
            advantages {
              title
              description
              icon
              visual {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Layout 
    slider={false} 
    switchLink={data.allContentfulSecteurs.edges[1].node.permalink} 
    lang="fr-CA"
    title={data.allContentfulSecteurs.edges[0].node.name} 
    subTitle="L'affichage dynamique en"
    icon={<Factories/>}>
      <Seo title="Affichage dynamique en entreprise pour vos employés"
      description="Communiquez efficacement avec vos employés, peu importe le quart ou le secteur de votre entreprise, avec le système de gestion des messages centralisé qui vous est offert par Attractif." />
      <Industry datas={data.allContentfulSecteurs.edges[0].node}/>
    </Layout>
  )
}

export default FactoriesIndustryPageFr
